import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FileTextIcon, ListIcon, LucideAngularModule } from 'lucide-angular';
import { MarkdownModule } from 'ngx-markdown';
import { SummaryResponseDTO } from '../../../../../api_ts_sdk';

@Component({
  selector: 'app-transcription-result',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, MarkdownModule],
  templateUrl: './transcription-result.component.html',
  styleUrl: './transcription-result.component.css'
})
export class TranscriptionResultComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) rawTranscription!: string;
  @Input({ required: true }) summary!: SummaryResponseDTO;

  showRaw = false;

  readonly FileTextIcon = FileTextIcon;
  readonly ListIcon = ListIcon;
}
