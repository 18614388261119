import { Injectable } from "@angular/core";
import { BehaviorSubject, map, switchMap } from "rxjs";
import { CostEstimateResponseDTO, ProjectControllerService } from "../../../../api_ts_sdk";
import { CostStatus } from "../model/cost-status";
import { AudioCompressionService } from "../../../services/audio-compression/audio-compression-service";
import { Cost } from "../model/cost";

@Injectable({
  providedIn: 'root'
})
export class ProjectFileService {
  private readonly files = new BehaviorSubject<File[]>([]);
  private readonly costs = new BehaviorSubject<Cost[]>([]);

  // Public observables
  readonly files$ = this.files.asObservable();
  readonly costs$ = this.costs.asObservable();
  readonly totalCost$ = this.costs$.pipe(
    map(costs => costs.reduce((total, cost) =>
      total + (cost.estimate?.cost || 0), 0))
  );

  get filesSnapshot(): File[] {
    return this.files.value;
  }

  get costsSnapshot(): CostEstimateResponseDTO[] {
    return this.costs.value.map(pc => pc.estimate!);
  }

  get totalCostSnapshot(): number {
    return this.costsSnapshot.reduce((total, cost) => total + (cost.cost || 0), 0)
  }

  constructor(
    private projectController: ProjectControllerService,
    private audioCompressor: AudioCompressionService,
  ) {}

  addFile(file: File) {
    if (this.fileExists(file.name)) return;

    const currentFiles = this.files.value;

    this.files.next([...currentFiles, file]);

    this.calculateCost(file);
  }

  removeFile(filename: string) {
    const currentFiles = this.files.value;
    this.files.next(currentFiles.filter(f => f.name !== filename));

    const currentCosts = this.costs.value;
    this.costs.next(currentCosts.filter(c => c.filename !== filename));
  }

  costsAreValid(): boolean {
    return this.costs.value.length > 0 && this.costs.value.every(c => c.state == CostStatus.success);
  }

  reset(): void {
    this.files.next([]);
    this.costs.next([]);
  }

  private calculateCost(file: File): void {
    const cost = this.createInitialCost(file);

    this.addCost(cost);

    this.getDuration(file)
      .pipe(
        switchMap(duration => this.getEstimate(file.name, duration)),
      )
      .subscribe({
        next: (estimate) => this.handleCostSuccess(file.name, estimate),
        error: (error) => this.handleCostError(cost.filename, error)
      });
  }

  private createInitialCost(file: File): Cost {
    return {
      filename: file.name,
      state: CostStatus.loading
    };
  }

  private getDuration(file: File) {
    return this.audioCompressor.getDuration(file.name, file)
  }

  private getEstimate(filename: string, durationInSeconds: number) {
    return this.projectController.estimateCost({
      fileName: filename,
      durationInSeconds: durationInSeconds
    });
  }

  private handleCostSuccess(filename: string, estimate: any) {
    this.updateCost(filename, {
      state: CostStatus.success,
      estimate: estimate
    });
  }

  private handleCostError(filename: string, error: any) {
    this.updateCost(filename, { state: CostStatus.error });
    console.error('Error calculating cost:', error);
  }

  private addCost(cost: Cost): void {
    this.costs.next([...this.costs.value, cost]);
  }

  private updateCost(filename: string, patch: Partial<Cost>): void {
    const currentCosts = this.costs.value;
    const index = currentCosts.findIndex(c => c.filename === filename);

    if (index === -1) {
      console.warn(`Cost with filename ${filename} not found.`);
      return;
    }

    // Create new array with updated cost
    const updatedCosts = [...currentCosts];
    updatedCosts[index] = { ...currentCosts[index], ...patch } as Cost;

    // Emit new array
    this.costs.next(updatedCosts);
  }

  private fileExists(filename: string): boolean {
    return this.files.value.some(f => f.name === filename);
  }
}
