import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LucideAngularModule, XCircleIcon, CheckCircle2Icon } from 'lucide-angular'

@Component({
  selector: 'app-alert-dialog',
  standalone: true,
  imports: [
    LucideAngularModule,
  ],
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.css'
})
export class AlertDialogComponent {
  @Input() title = '';
  @Input() body = '';
  @Input() buttonLabel = '';
  @Input() isError = false;
  @Output() onButtonClick = new EventEmitter<void>();

  readonly XCircleIcon = XCircleIcon;
  readonly CheckCircle2Icon = CheckCircle2Icon;
}
