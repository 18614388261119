import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProjectFileRowComponent } from '../project-file-row/project-file-row.component';
import { Cost } from '../../model/cost';

@Component({
  selector: 'app-cost-list',
  standalone: true,
  imports: [ProjectFileRowComponent, CommonModule],
  templateUrl: './cost-list.component.html',
  styleUrl: './cost-list.component.css'
})
export class CostListComponent {

  @Input() allowDeleting: boolean = true;

  @Input() costs: Cost[] | null = [];

  public get total(): number {
    if (this.costs) {
      return this.costs.reduce((curr, next) => curr + (next.estimate?.cost ?? 0), 0);
    } else {
      return 0;
    }
  }
}
