import { Injectable } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { BehaviorSubject, catchError, distinctUntilChanged, filter, first, last, map, mergeMap, Observable, of, retry, switchMap, takeWhile, tap, throwError, timeout, timer } from "rxjs";
import { WizardStep } from "../model/wizard-step";
import { notWhitespaceOnly } from "../../../utili/validators/not-whitespace-only-validator";
import { ProjectControllerService } from "../../../../api_ts_sdk";
import { ProjectFileService } from "./project-file-service.service";
import { filesToUploadFromEstimates } from "../../../utili/builders/filesToUploadFromEstimates";
import { FileUploadService } from "./file-upload.service";
import { AudioCompressionService } from "../../../services/audio-compression/audio-compression-service";
import { ProjectRepository } from "../../../services/project.repository";
import { ProjectPaymentComponentStatus } from "../components/steps/step-2-payment/project-payment.component";

@Injectable({
  providedIn: 'root'
})
export class CreateProjectWizardService {
  private readonly step = new BehaviorSubject<WizardStep>(WizardStep.loading);

  private paymentStatus = new BehaviorSubject<ProjectPaymentComponentStatus>(ProjectPaymentComponentStatus.awaitingPayment);
  readonly paymentStatus$ = this.paymentStatus.asObservable();

  projectId: number | null = null;

  paymentPreferenceId: string | null = null;

  get costs$() {
    return this.projectFileService.costs$;
  }

  get totalCost() {
    return this.projectFileService.totalCostSnapshot;
  }

  get fileUploads$() {
    return this.fileUploadService.filesUploads$;
  }

  get projectName(): string { return this.nameControl.value as string };

  readonly nameControl = new FormControl('', [
    Validators.required,
    notWhitespaceOnly()
  ]);

  constructor(
    private projectController: ProjectControllerService,
    private projectFileService: ProjectFileService,
    private fileUploadService: FileUploadService,
    private audioCompressor: AudioCompressionService,
    private projectRepository: ProjectRepository,
  ) { }

  initialize() {
    this.audioCompressor.init()
      .then(() => this.step.next(WizardStep.selectingFiles));
  }


  reset(): void {
    this.projectId = null;
    this.step.next(WizardStep.selectingFiles);
    this.nameControl.reset();
    this.paymentStatus.next(ProjectPaymentComponentStatus.awaitingPayment);
    this.projectFileService.reset();
    this.fileUploadService.reset();
  }

  readonly currentStep$ = this.step.asObservable();

  continueToPayment() {
    const files = filesToUploadFromEstimates(this.projectFileService.costsSnapshot);

    const createOrUpdateProject = this.projectId == null
      ? this.projectController.createProject({ files, name: this.projectName })
      : this.projectController.updateProject(this.projectId, { files })

    return createOrUpdateProject
      .pipe(
        tap(project => this.projectId = project.id!),                                         // Guardar el project ID
        switchMap(project => this.projectController.getPaymentPreference(project.id!)),       // Pedir al back la preferencia de pago
        tap(paymentPreference => this.paymentPreferenceId = paymentPreference.preferenceId!), // Guardar la preferencia
        first(),                                                                              // Tomar solo el primer valor de esta cadena
        tap(() => this.step.next(WizardStep.aboutToPay)),                              // Ir al sgte. paso
        catchError(error => {
          throw new Error(`Hubo un error al crear o actualizar el proyecto: ${error}`);
        })
      );
  }

  private readonly POLLING_INTERVAL = 3000; // 3s
  private readonly MAX_DURATION = 1 * 60 * 1000; // Continuar probando durante un minuto
  private readonly MAX_RETRIES = 20;

  payAndAdvance(paymentForm: object): Observable<any> {
    return this.processPayment(paymentForm)
      .pipe(
        mergeMap(() => this.pollPaymentStatus()),
        tap(() => this.advance()),
        catchError(this.handlePaymentError)
      );
  }

  uploadFilesAndAdvance() {
    return this.fileUploadService
      .uploadFiles(this.projectId!, this.projectFileService.filesSnapshot)
      .pipe(
        last(),
        switchMap(() => this.projectController.getProject(this.projectId!)),
        tap((project) => this.projectRepository.add(project)),
        tap(() => this.advance())
      );
  }

  private processPayment(paymentForm: object): Observable<any> {
    return this.projectController.processPayment(this.projectId!, paymentForm);
  }

  private pollPaymentStatus(): Observable<any> {
    return timer(0, this.POLLING_INTERVAL)
    .pipe(
      mergeMap(() => this.getAndValidatePaymentStatus()),
      takeWhile(status => status !== 'APPROVED', true ),
      filter(status => status === 'APPROVED'),
      timeout(this.MAX_DURATION),
      retry({
        count: this.MAX_RETRIES,
        delay: this.POLLING_INTERVAL
      }),
    );
  }

  private getAndValidatePaymentStatus(): Observable<string> {
    return this.projectController.getPaymentStatus(this.projectId!).pipe(
      tap(this.validatePaymentStatus)
    );
  }

  private validatePaymentStatus(status: string): void {
    if (status === 'REJECTED') {
      throw new Error('Payment was rejected');
    }
  }

  private handlePaymentError(error: any): Observable<never> {
    let errorMessage = this.buildErrorMessage(error);
    return throwError(() => new Error(errorMessage));
  }

  private buildErrorMessage(error: any): string {
    if (error.name === 'TimeoutError') {
      return 'El tiempo de espera para el pago ha expirado';
    }

    let baseMessage = 'Hubo un error procesando el pago';
    return error.message ? `${baseMessage}: ${error.message}` : baseMessage;
  }

  advance(): Observable<any> {
    switch (this.step.value) {
      case WizardStep.loading:
        this.step.next(WizardStep.selectingFiles);
        break;
      case WizardStep.selectingFiles:
        return this.continueToPayment();
      case WizardStep.aboutToPay:
        this.step.next(WizardStep.uploadingFiles);
        break;
      case WizardStep.uploadingFiles:
        this.step.next(WizardStep.done);
        break;
      case WizardStep.done:
        this.step.next(WizardStep.selectingFiles);
        break;
    }

    return of(this.step.value);
  }

  goBack() {
    switch (this.step.value) {
      case WizardStep.selectingFiles:
        throw new Error(`No hay paso anterior a ${this.step.value}`);
      case WizardStep.aboutToPay:
        this.step.next(WizardStep.selectingFiles);
        break;
      case WizardStep.uploadingFiles:
        throw new Error(`No deberías poder volver atrás mientras estás subiendo archivos 🤔`);
      case WizardStep.done:
        throw new Error(`No deberías poder volver atrás desde el step 'done' 🤔`);
    }
  }
}
