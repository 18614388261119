import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadProgressComponent } from '../../file-upload-progress/file-upload-progress.component';
import { CreateProjectWizardService } from '../../../services/create-project-wizard.service';

@Component({
  selector: 'app-project-file-upload',
  standalone: true,
  imports: [CommonModule, FileUploadProgressComponent],
  templateUrl: './project-file-upload.component.html',
  styleUrls: ['./project-file-upload.component.css']
})
export class ProjectFileUploadComponent implements OnInit, OnDestroy {

  get filesUploads$() {
    return this.projectWizardService.fileUploads$;
  }

  constructor(
    private projectWizardService: CreateProjectWizardService,
  ) { }

  ngOnInit(): void {
    this.projectWizardService.uploadFilesAndAdvance().subscribe();

    // Añadir listener para advertir al usuario al intentar cerrar la pestaña
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  ngOnDestroy(): void {
    // Remover listener cuando el componente se destruya
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  onBeforeUnload = (event: BeforeUnloadEvent): void => {
    // Configurar el mensaje de advertencia
    event.preventDefault();
    event.returnValue = '';
  };
}
