<div class="bg-white rounded-xl shadow-xl h-[600px]">
  <div class="p-6 flex flex-col h-[600px]"> <!-- Fixed height container -->
    <h2 class="text-xl font-bold mb-4">Nuevo proyecto</h2>

    <div class="flex flex-col flex-1 min-h-0"> <!-- min-h-0 enables proper overflow -->
      <span>Estás por pagar la transcripción de estos archivos</span>
      <div class="flex-1 min-h-0"> <!-- Container for app-cost-list -->
        <app-cost-list class="h-full flex flex-1 flex-col" [costs]="costs$ | async" [allowDeleting]="false">
        </app-cost-list>
      </div>
    </div>

    <div class="flex justify-between mt-4 pt-4 border-t" style="gap: 16rem;">
      <button (click)="back()" class="btn mt-4">Volver</button>
      <app-pago-dynamic style="min-width: 280px; min-height: 103px;" [preferenceId]="preferenceId" [amount]="totalCost" (onSubmit)="onPaymentSubmit($event)"/>
    </div>
  </div>
</div>
