import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, map, merge, startWith, Subscription, tap } from 'rxjs';
import { CreateProjectWizardService } from '../../../services/create-project-wizard.service';
import { ProjectFileService } from '../../../services/project-file-service.service';
import { CostListComponent } from '../../cost-list/cost-list.component';

@Component({
  selector: 'app-project-details',
  standalone: true,
  imports: [
    CommonModule,
    CostListComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.css'
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {

  get nameControl() {
    return this.projectWizardState.nameControl;
  }

  loading = false;

  get firstError$() {
    return merge(
      this.nameControl.valueChanges,  // Hay que suscribir a ambos para no perder cambios porque
      this.nameControl.statusChanges  // el status puede cambiar independientemente del valor..
    ).pipe(
      startWith(''),
      map(() => this.takeFirstError())
    );
  }

  get costs$() {
    return this.projectFileService.costs$;
  }

  readonly canContinue$ = new BehaviorSubject<boolean>(false);

  constructor(
    public projectWizardState: CreateProjectWizardService,
    public projectFileService: ProjectFileService,
  ) { }

  private subscription: Subscription | undefined;

  ngOnInit(): void {
    this.subscription = merge(
      this.nameControl.valueChanges,
      this.costs$
    ).subscribe(() => this.validateStep());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onFileSelect(event: any) {
    const files = Array.from(event.target.files) as File[];

    for (let file of files) {
      this.projectFileService.addFile(file);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    const files = Array.from(event.dataTransfer?.files || []) as File[];

    for (let file of files) {
      this.projectFileService.addFile(file);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  continue() {
    this.loading = true;

    this.projectWizardState.advance().subscribe({
      next: () => this.loading = false
    });
  }

  private validateStep() {
    if (!this.nameControl.valid) {
      this.canContinue$.next(false);
      return;
    }

    if (!this.projectFileService.costsAreValid()) {
      this.canContinue$.next(false);
      return;
    }

    this.canContinue$.next(true);
  }

  private takeFirstError() {
    const errors = this.nameControl.errors;
    if (errors != null) {
      return Object.keys(errors)[0];
    }
    return null;
  }
}
