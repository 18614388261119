// payment.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentForm } from '../model/payment-form.interface';
import { BasePaymentForm } from '../base-payment-form';
import { SessionService } from '../../../../../services/session.service';
import { environment } from '../../../../../../environments/environment';

declare global {
  interface Window {
    MercadoPago: any;
  }
}

@Component({
  selector: 'app-mercado-pago',
  templateUrl: './mercado-pago.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class MercadoPagoComponent extends BasePaymentForm implements OnInit {
  private mp: any;
  private bricksBuilder: any;
  loading = true;
  error = '';

  @Input() override preferenceId: string | null = null;
  @Input() override amount: number | null = null;
  @Output() override onSubmit = new EventEmitter<PaymentForm>();

  constructor(
    private session: SessionService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.preferenceId == null) {
      throw Error("El preferenceId NO puede ser null")
    }

    this.loadMercadoPagoScript().then(() => {
      this.initializeMercadoPago();
    });
  }

  private loadMercadoPagoScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load MercadoPago SDK'));
      document.body.appendChild(script);
    });
  }

  private async initializeMercadoPago() {
    try {
      this.mp = new window.MercadoPago(environment.mercadoPagoPublicKey, {
        locale: 'es-AR'
      });

      this.bricksBuilder = this.mp.bricks();

      await this.renderPaymentBrick(this.preferenceId!);

      this.loading = false;
    } catch (err: any) {
      this.error = 'Failed to initialize payment form: ' + err.message;
      this.loading = false;
    }
  }

  private async renderPaymentBrick(preferenceId: string) {
    if (this.amount == null) throw new Error("MercadoPagoComponent: amount input can't still be null at this stage");

    const settings = {
      initialization: {
        redirectMode: 'blank',
        preferenceId: preferenceId,
        amount: this.amount,
        payer: {
          email: this.session.currentUser?.email
        },
      },
      customization: {
        visual: {
          style: {
            theme: 'default'
          },
        },
        paymentMethods: {
          creditCard: 'all',
          debitCard: 'all',
          mercadoPago: 'wallet_purchase'
        },
      },
      callbacks: {
        onReady: () => { },
        onSubmit: (paymentForm: any) => this.onSubmit.emit(paymentForm),
        onError: (error: any) => {
          console.error('Brick error:', error);
          this.error = 'Payment error: ' + error.message;
        }
      }
    };

    try {
      const paymentBrick = await this.bricksBuilder.create(
        'wallet',
        'paymentBrick_container',
        settings
      );

      // Store the controller if you need to update the brick later
      // this.paymentBrickController = paymentBrick;

    } catch (error: any) {
      this.error = 'Error creating payment form: ' + error.message;
    }
  }
}
