import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProjectFileService } from '../../services/project-file-service.service';
import { TimeFormatterPipe } from '../../../../utili/timerFormatter';
import { Cost } from '../../model/cost';

@Component({
  selector: 'app-project-file-row',
  standalone: true,
  imports: [CommonModule, TimeFormatterPipe],
  templateUrl: './project-file-row.component.html',
  styleUrl: './project-file-row.component.css'
})
export class ProjectFileRowComponent {
  @Input() cost: Cost | undefined;
  @Input() allowDeleting: boolean = false;

  constructor(private projectFileService: ProjectFileService) {}

  onDeleteRowTapped(fileName: string): void {
    this.projectFileService.removeFile(fileName);
  }
}
