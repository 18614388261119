import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateProjectWizardService } from '../../../services/create-project-wizard.service';
import { CostListComponent } from '../../cost-list/cost-list.component';
import { PagoHostComponent } from '../../payment-form/payment-form.component';
import { PaymentForm } from '../../payment-form/model/payment-form.interface';

@Component({
  selector: 'app-project-payment',
  standalone: true,
  imports: [
    CommonModule,
    CostListComponent,
    ReactiveFormsModule,
    PagoHostComponent
],
  templateUrl: './project-payment.component.html',
  styleUrl: './project-payment.component.css'
})
export class ProjectPaymentComponent {

  get status$() {
    return this.projectWizardState.paymentStatus$;
  }

  get costs$() {
    return this.projectWizardState.costs$;
  }

  get totalCost() {
    return this.projectWizardState.totalCost;
  }

  get preferenceId() {
    return this.projectWizardState.paymentPreferenceId!;
  }

  constructor(
    public projectWizardState: CreateProjectWizardService,
  ) {}

  back(): void {
    this.projectWizardState.goBack();
  }

  onPaymentSubmit(form: PaymentForm) {
    this.projectWizardState.payAndAdvance(form)
      .subscribe(() => {});
  }
}

export enum ProjectPaymentComponentStatus {
  awaitingPayment = 'awaitingPayment',
  checkingPayment = 'checkingPayment',
  confirmed = 'confirmed',
  failed = 'failed',
}
