import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApiModule, Configuration } from '../api_ts_sdk';
import { authInterceptor } from './interceptors/auth.interceptor';
import { errorInterceptor } from './interceptors/error.interceptor';
import { environment } from '../environments/environment';
import { AudioCompressionService } from './services/audio-compression/audio-compression-service';
import { audioCompressionFactory } from './services/audio-compression/audio-compression-factory';
import { provideMarkdown } from 'ngx-markdown';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([authInterceptor, errorInterceptor])
    ),
    importProvidersFrom(
      ApiModule.forRoot(() => new Configuration({
        basePath: environment.apiUrl
      }))
    ),
    {
      provide: AudioCompressionService,
      useFactory: audioCompressionFactory
    },
    provideMarkdown({
      // markedOptions: {}
    })
  ]
};
