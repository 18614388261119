<div
  class="bg-base-100 border flex flex-col max-w-4xl mt-4 mx-auto overflow-y-auto px-4 pb-64 rounded-t-lg shadow-sm w-full"
  style="flex: 1;">

  <div class="flex items-center justify-between mb-6 sticky top-0 bg-base-100 border-b py-4">
    <h1 class="text-2xl font-semibold">{{title}}</h1>
    <div class="flex items-center gap-2 border rounded-lg p-1">
      <button (click)="showRaw = false" class="flex items-center gap-2 px-4 py-2 rounded-md"
        [class.bg-base-200]="!showRaw">
        <i-lucide [img]="ListIcon" class="w-4 h-4" />
        <span>Resumen</span>
      </button>
      <button (click)="showRaw = true" class="flex items-center gap-2 px-4 py-2 rounded-md"
        [class.bg-base-200]="showRaw">
        <i-lucide [img]="FileTextIcon" class="w-4 h-4" />
        <span>Transcripción</span>
      </button>
    </div>
  </div>

  @if (showRaw) {
  <markdown class="mx-16 text-justify leading-loose" [data]="rawTranscription"></markdown>
  } @else {
  <div class="mb-4 mx-16">
    <div class="border-b pb-4">
      <h2 class="text-lg font-medium mb-2">Puntos Clave</h2>
      <ul class="list-disc list-inside space-y-2">
        @for (point of summary.keyPoints; track point) {
        <li>{{ point }}</li>
        }
      </ul>
    </div>

    <h2 class="text-lg font-medium mt-4 mb-2">Resumen</h2>
    <markdown class="text-justify leading-loose" [data]="summary.summary"></markdown>
  </div>
  }
</div>