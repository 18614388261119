import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ComponentRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { MockCheckoutBricksComponent } from "./mock-checkout-bricks/mock-checkout-bricks.component";
import { PaymentForm } from "./model/payment-form.interface";
import { BasePaymentForm } from "./base-payment-form";
import { MercadoPagoComponent } from "./mercado-pago/mercado-pago.component";
import { environment } from "../../../../../environments/environment";
import { Mocks } from "../../../../../environments/mocks.enum";


export function pagoComponentFactory(): Type<BasePaymentForm> {
  return environment.mocks.includes(Mocks.payment) ? MockCheckoutBricksComponent : MercadoPagoComponent;
}

@Component({
  selector: 'app-pago-dynamic',
  standalone: true,
  imports: [CommonModule],
  template: `<div #container></div>`
})
export class PagoHostComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @Input() preferenceId: string | null = null;
  @Input() amount: number | null = null;
  @Output() onSubmit = new EventEmitter<PaymentForm>();

  private componentRef?: ComponentRef<BasePaymentForm>;

  ngAfterViewInit() {
    const componentType = pagoComponentFactory();
    this.componentRef = this.container.createComponent(componentType);

    // Initial input setup
    this.updateInputs();

    // Setup output subscription
    this.componentRef.instance.onSubmit.subscribe(form => {
      this.onSubmit.emit(form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only update if componentRef exists (after view init)
    if (this.componentRef) {
      this.updateInputs();
    }
  }

  private updateInputs() {
    if (this.componentRef) {
      this.componentRef.setInput('preferenceId', this.preferenceId);
      this.componentRef.setInput('amount', this.amount);
    }
  }

  ngOnDestroy() {
    this.componentRef?.destroy();
  }
}
