import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CreateProjectWizardService } from './services/create-project-wizard.service';
import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";
import { ProjectDetailsComponent } from './components/steps/step-1-details/project-details.component';
import { ProjectPaymentComponent } from './components/steps/step-2-payment/project-payment.component';
import { ProjectFileUploadComponent } from './components/steps/step-3-upload/project-file-upload.component';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ProjectDetailsComponent,
    ProjectPaymentComponent,
    ProjectFileUploadComponent,
    AlertDialogComponent,
]
})
export class CreateProjectComponent implements OnInit {

  get step$() {
    return this.newProjectWizard.currentStep$;
  }

  constructor(
    public newProjectWizard: CreateProjectWizardService
  ) { }

  ngOnInit(): void {
    this.newProjectWizard.initialize();
  }

  reset(): void {
    this.newProjectWizard.reset();
  }
}
