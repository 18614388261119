import { Env } from "./env";
import { Mocks } from "./mocks.enum";

export const environment: Env = {
  apiUrl: 'https://tito.com.ar/api',
  mercadoPagoPublicKey: 'TEST-e2ec125e-9a5a-483e-9fc6-ab495a855a68',
  mocks: [
    // Mocks.payment
  ],
  logThings: true
};
